import React from 'react';
import Img from 'gatsby-image';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '../../components/gatsby-link';


const useStyles = props =>
  makeStyles(theme => ({
    card: {
      marginBottom: '3rem',
    },
    media: {
      height: 140,
    },
    link: {
      color:'inherit',
      textDecoration:'none',
    },
    blogTitle: {
      textAlign:'center',
    },
}));

const BlogIndex = (props) => {

  const data = props.data.contentfulComponentBlogIndex1;
  const postData = props.data.allContentfulPost.edges;

  const classes = useStyles({ ...props, data })();

  const link = name => (name === 'index' ? '/' : `/${name}`);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return(
    <Box mt={13}>
      <Grid container>
        <Grid item xs={1} sm={2} md={3} key="emtpy">
        </Grid>
        <Grid item xs={10} sm={8} md={6} key="data">
          <h1 className={classes.blogTitle}>{data.title}</h1>
          {postData.map( option => {
            return(
              <Card className={classes.card} key={option.node.id}>
                <CardActionArea>
                  <Link className={classes.link} to={link('blog/'+option.node.slug)}>
                    {option.node.image && (
                      <Img fluid={option.node.image.fluid}/>
                    )}
                    <CardContent>
                      <h1>{option.node.title}</h1>
                      <h2>{option.node.subtitle}</h2>
                      <h4>{option.node.author}{' - '}{option.node.datePosted}{' '}·{' '}{option.node.timeToRead}</h4>
                    </CardContent>
                  </Link>
                </CardActionArea>
                <CardActions>
                  <div>
                    <Button disableElevation variant='contained' size="large" color="secondary" onClick={() =>  {handleClickOpen(); navigator.clipboard.writeText('https://www.universifyeducation.com/blog/'+option.node.slug)}}>
                      Share
                    </Button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{"Copied URL to Clipboard"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          OK
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <Button variant="contained" disableElevation size="large" color='primary'  onClick={() => props.navigate(link('blog/'+option.node.slug))}>
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            )
          })
          }
        </Grid>
        <Grid item xs={1} sm={2} md={3} key="emtpy1">  
        </Grid>
      </Grid>
    </Box>
  )
}

export default BlogIndex;


